<script setup lang="ts">
import { onMounted, ref, computed } from "vue";
import start from "@ice/stark/lib/start";
import { registerMicroApps } from "@ice/stark/lib/apps";
import { datagustoLegacyHost, isStandalone } from "@/env";
import {
  state as auth0State,
  createClient,
  handleCallback,
  getAccessToken,
} from "@/CrossCuttingConcern/Authentication/Services/auth0/ClientWrapper";
import { useAuthStore } from "@/store/auth";
import MainHeader from "@/components/layouts/header/MainHeader.vue";
import MainSidebar from "@/components/layouts/sidebar/MainSidebar.vue";
import DatagustoLoadingCircular from "@/components/common/DatagustoLoadingCircular/DatagustoLoadingCircular.vue";

const microAppsActive = ref(false);
const microAppsLoading = ref(false);

const auth0ClientState = computed(() => auth0State); // computed to show on vue-devtool
const authStore = useAuthStore();

const handleAuthentication = async () => {
  await createClient();
  await authStore.checkLoggedIn();
  await handleCallback();

  const token = await getAccessToken();

  if (token) {
    console.log("💽  storing auth token...");
    await authStore.setTokenSilently({ token });
    console.log("✅  auth token stored!");
  } else {
    console.error(
      "💀 Couldn't set auth token by auth0. There might be a network error or auth0 client error."
    );
  }
};

if (!authStore.isLoggedIn) {
  handleAuthentication();
}

onMounted(async () => {
  // To query mount target, this layout-main app should first be mounted.
  // Plase call `document.getElementById()` within onMounted vue lifecycle hook.`
  const microAppMountId = "icestark-container";
  const container = document.getElementById(microAppMountId) ?? undefined; // container opt attr for icestark only permits undefined, not null.

  if (!container) {
    console.error(
      `😢 microapp mount target ${microAppMountId} is not found! Microapp mount should be failled.`
    );
  }

  if (isStandalone)
    console.log(
      `🧊 icestark: Currently, this app is running as standalone state.`
    );

  if (!isStandalone) {
    registerMicroApps([
      {
        name: "datagusto-vue2",
        activePath: (url) => {
          // Please sort in alphabetical order.
          const urlList = [
            "/account",
            "/admin",
            "/class-component",
            "/create-ai",
            "/dashboard",
            "/external-accounts",
            "/folders",
            "/home",
            "/models",
            "/my-recipes",
            "/recipes",
            "/sample",
            "/settings",
            "/simulate",
            "/",
          ];

          const urlOrElse = url ?? "";

          return urlList.some((u) => urlOrElse.includes(u));
        },
        title: "datagusto",
        loadScriptMode: "import",
        entry: `${datagustoLegacyHost}/index.html`,
        container,
      },
    ]);

    // icestark lifecycle hook
    start({
      onLoadingApp: () => {
        microAppsLoading.value = true;
      },
      onFinishLoading: () => {
        microAppsLoading.value = false;
      },
      onRouteChange: (url: string, pathname: string, query: object) => {
        console.log(
          `🧊 icestark/onRouteChange: (url: ${url}, pathname: ${pathname}, query:`,
          query
        );
      },
      onActiveApps: (activeApps) => {
        microAppsActive.value = !!(activeApps || []).length;
      },
      onAppEnter: (appConfig) => {
        console.log(`🧊 icestark/onAppEnter ${appConfig.name} entered.`);
        console.log(`🧊 icestark/onAppEnter appConfig`, appConfig);
      },
      onError: (error) => {
        console.error(`🧊 icestark/onError`, error);
        throw new Error(`🧊 icestark/onError`, error);
      },
    });
  }
});
</script>

<template>
  <MainHeader />
  <div class="layout-main__content">
    <MainSidebar />
    <!-- micro app entry -->
    <div id="icestark-container" class="root-container__spa-entry"></div>
    <!-- icestark-main standalone entry -->
    <router-view v-if="!microAppsActive" />
    <DatagustoLoadingCircular v-if="microAppsLoading" class="dg-loader" />
  </div>
</template>

<style lang="scss" scoped>
.layout-main__content {
  display: flex;
  flex-direction: row;
}

.root-container__spa-entry {
  width: calc(100vw - 250px); // 250px stands for sidebar width
}

.dg-loader {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: $dg-loading-circular-z-index;
  width: 100;
  outline: none;
  pointer-events: none;
  margin-right: $g-size-24;
  margin-bottom: $g-size-24;
}
</style>
