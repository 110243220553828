import { defineStore, acceptHMRUpdate } from "pinia";
import * as Sentry from "@sentry/vue";
import { errorHandlerModule } from "@/store/ui/errorHandler/index";
import { useAuthStore } from "@/store/auth";
import { IBackendError } from "@/error/types";
import { ErrorFactory } from "@/error/factory";

const id = "error";
export const useErrorStore = defineStore({
  id,
  state: () => ({}),
  getters: {},
  actions: {
    async handleApiError(payload: any): Promise<void> {
      const authStore = useAuthStore();

      Sentry.captureException(payload);

      if (payload.response && payload.response.status === 401) {
        authStore.logOut();

        return;
      }

      if (payload.response) {
        // The request was made and the status is out of the
        // success range 2XX
        const backendError: IBackendError =
          payload.response.data &&
          payload.response.data.detail &&
          payload.response.data.detail.error;

        if (backendError) {
          errorHandlerModule.actions.handleBackendError({
            backendError,
          });
        } else {
          errorHandlerModule.actions.handleFrontendError({
            frontendError: ErrorFactory.createUnexpectedBase(),
          });
        }
      } else {
        errorHandlerModule.actions.handleFrontendError({
          frontendError: ErrorFactory.createNetworkBase(),
        });
      }

      throw payload;
    },
  },
});

// enable vite HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useErrorStore, import.meta.hot));
}
