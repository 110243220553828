<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    exact?: boolean;
    disabled?: boolean;
    height?: number | string;
    width?: number | string;
  }>(),
  {
    exact: true,
    disabled: false,
    height: undefined,
    width: undefined,
  }
);

const style = computed(() => {
  let style = {};

  if (props.height) {
    style = {
      ...style,
      height: `${props.height}px`,
    };
  }

  if (props.width) {
    style = {
      ...style,
      width: `${props.width}px`,
    };
  }

  return style;
});
</script>

<template>
  <button
    v-if="$attrs.disabled || props.disabled"
    :style="style"
    class="base-btn base-btn--inverse--disabled"
    disabled
  >
    <slot />
  </button>
  <button v-else class="base-btn base-btn--inverse" :style="style">
    <slot />
  </button>
</template>

<style scoped lang="scss">
@import "@/components/base/BaseButton/Variants/BaseButton.scss";
</style>
