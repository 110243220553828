import { defineStore, acceptHMRUpdate } from "pinia";
import { AuthTokenHandler } from "@/CrossCuttingConcern/Authentication/Services/AuthTokenHandler";
import { auth } from "@/interfaces";
import { api } from "@/api";
import { useErrorStore } from "@/store/error";

const tokenHandler = new AuthTokenHandler();

interface State {
  token: string | null;
  isLoggedIn: boolean;
  logInError: boolean;
  userProfile: auth.IUserProfile | null;
}

const id = "auth";
export const useAuthStore = defineStore({
  id,
  state: (): State => ({
    token: null,
    isLoggedIn: false,
    logInError: false,
    userProfile: null,
  }),
  getters: {
    hasAdminAccess: (state: State) => {
      return (
        (state.userProfile &&
          state.userProfile.is_superuser &&
          state.userProfile.is_active) ??
        false // userProfileがnullの場合、falseとする
      );
    },
    hasChefBadge: (state: State) => {
      return (
        (state.userProfile && state.userProfile.chef_badges.length > 0) ?? false
      );
    },
  },
  actions: {
    async checkLoggedIn() {
      if (!this.$state.isLoggedIn) {
        if (!this.$state.token) {
          const localToken = tokenHandler.localStorageRepository.getOrNull();
          if (localToken) {
            this.$patch({
              token: localToken,
            });
          }
        }
        if (this.$state.token) {
          try {
            const res = await api.v2.users.me();
            this.$patch({
              isLoggedIn: true,
              userProfile: res.data.user,
            });
          } catch (error) {
            tokenHandler.remove();
          }
        } else {
          tokenHandler.remove();
        }
      }
    },
    /**
     * Sets token silently,
     * silently here means without UI showing auth status.
     */
    async setTokenSilently(payload: { token: string }) {
      try {
        const token = payload.token;
        tokenHandler.store(token);

        this.$patch({
          token,
        });

        await this.getUserProfile();
      } catch (error) {
        this.$patch({
          logInError: true,
        });
        this.logOut();
      }
    },
    async getUserProfile() {
      const errorStore = useErrorStore();

      try {
        const response = await api.v2.users.me();
        if (response.data) {
          this.$patch({
            userProfile: response.data.user,
          });
        }
      } catch (error) {
        errorStore.handleApiError(error);
      }
    },
    logOut() {
      tokenHandler.remove(); // remove from localstorage & cookie

      this.$patch({
        isLoggedIn: false,
        token: "",
      });
    },
  },
});

// enable vite HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAuthStore, import.meta.hot));
}
