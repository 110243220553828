<script setup lang="ts">
import { computed, ref } from "vue";
import { CONFIG } from "@/config/index";
import { useAuthStore } from "@/store/auth";
import { logout as auth0Logout } from "@/CrossCuttingConcern/Authentication/Services/auth0/ClientWrapper";
import DropdownMenu from "@/components/common/DropdownMenu/DropdownMenu.vue";
import DropdownMenuContent, {
  LinkItem,
} from "@/components/common/DropdownMenu/DropdownMenuContent.vue";
import DropdownMenuWithCategory, {
  LinkItemWithCategory,
} from "@/components/common/DropdownMenu/DropdownMenuContentWithCategory.vue";
import AccountIcon from "~icons/mdi/account";
import HelpCircleIcon from "~icons/mdi/help-circle-outline";

const authStore = useAuthStore();
const hasAdminAccess = computed((): boolean => authStore.hasAdminAccess);
const hasChefBadge = computed((): boolean => authStore.hasChefBadge);

const accountSettingItemList = computed((): LinkItemWithCategory[] => {
  const list: LinkItemWithCategory[] = [];

  list.push({
    category: {
      label: "アカウント設定",
    },
    items: [
      {
        label: "プロフィール",
        href: "/account/edit",
      },
      {
        label: "外部連携",
        href: "/external-accounts",
      },
    ],
  });

  if (hasAdminAccess.value) {
    list.push({
      category: {
        label: "管理者設定",
      },
      items: [
        {
          label: "契約情報",
          href: "/admin/contracts",
        },
        {
          label: "ユーザー管理",
          href: "/admin/users/list",
        },
        {
          label: "IPアドレス管理",
          href: "/admin/ip-restrictions",
        },
      ],
    });
  }

  list.push({
    category: {
      label: "",
    },
    items: [
      {
        label: "ログアウト",
        onClick: () => logout(),
      },
    ],
  });

  return list;
});

const openInNewTab = (url: string) =>
  (window.open(url, "_blank") as any).focus();

const helpMenuItems = computed((): LinkItem[] => [
  {
    label: CONFIG.LINKS.CUSTOMER_RELATIONS.NOTION.MANUAL.LABEL,
    onClick: () =>
      openInNewTab(CONFIG.LINKS.CUSTOMER_RELATIONS.NOTION.MANUAL.LINK),
  },
  {
    label: CONFIG.LINKS.CUSTOMER_RELATIONS.ZENDESK.Q_AND_A.LABEL,
    onClick: () =>
      openInNewTab(CONFIG.LINKS.CUSTOMER_RELATIONS.ZENDESK.Q_AND_A.LINK),
  },
  {
    label: CONFIG.LINKS.CUSTOMER_RELATIONS.ZENDESK.COMMUNITY_TOP.LABEL,
    onClick: () =>
      openInNewTab(CONFIG.LINKS.CUSTOMER_RELATIONS.ZENDESK.COMMUNITY_TOP.LINK),
  },
  {
    label: CONFIG.LINKS.CUSTOMER_RELATIONS.ZENDESK.CONTACT.LABEL,
    onClick: () =>
      openInNewTab(CONFIG.LINKS.CUSTOMER_RELATIONS.ZENDESK.CONTACT.LINK),
  },
]);

const logout = async () => {
  authStore.logOut();
  await auth0Logout({ returnTo: window.location.origin });
};
</script>

<template>
  <div id="main-header" class="main-header">
    <div class="main-header__left">
      <router-link to="/">
        <div v-ripple class="main-header__title">
          <img src="@/assets/datagusto_logo.svg" />
        </div>
      </router-link>
    </div>
    <div class="main-header__right">
      <DropdownMenu key="account-settings-menu">
        <template #activator="{ toggle }">
          <div class="account-settings">
            <button
              id="toolbar-menu-btn"
              v-ripple
              class="menu-btn"
              flat
              @click="toggle()"
            >
              <img
                v-if="hasChefBadge"
                class="user-chef-badge"
                src="@/assets/has-chefbadge-profile.svg"
              />
              <AccountIcon v-else class="icon" />
            </button>
          </div>
        </template>
        <template #content="{ toggle }">
          <DropdownMenuWithCategory
            :item-list="accountSettingItemList"
            @click="toggle()"
          />
        </template>
      </DropdownMenu>
      <DropdownMenu key="adming-settings-menu">
        <template #activator="{ toggle }">
          <button
            id="toolbar-help"
            v-ripple
            class="menu-btn"
            flat
            @click="toggle()"
          >
            <HelpCircleIcon class="icon" />
          </button>
        </template>
        <template #content>
          <DropdownMenuContent :item-list="helpMenuItems" />
        </template>
      </DropdownMenu>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.main-header {
  height: 74px;
  display: flex;
  justify-content: space-between;

  .no-active::before {
    opacity: 0 !important;
  }

  &__title {
    padding: $g-size-20;
    height: 74px;
    cursor: pointer;
  }

  &__left {
    display: flex;
    justify-content: flex-end;
  }

  &__right {
    padding-right: $g-size-24;
    position: relative;
    display: flex;
    align-items: center;
  }
}

.account-settings {
  margin-right: $g-size-16;
}

.menu-btn {
  width: $g-size-40;
  height: $g-size-40;
  background-color: $blue-light-color;
  border-radius: 50%;
  padding: unset !important; // unset vuetify default style
  min-width: unset !important; // unset vuetify default style
}

.icon {
  font-size: $font-size-20;
  color: $brand-color;
  width: $g-size-40;
  height: $g-size-40;
  padding: $g-size-8;
}
.user-chef-badge {
  height: $g-size-40;
  width: $g-size-40;
}
</style>
