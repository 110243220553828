<script setup lang="ts">
import { computed } from "vue";
import BaseDialog from "@/components/base/BaseDialog/BaseDialog.vue";
import BaseBtn from "@/components/base/BaseButton/BaseButton.vue";

const emitVisible = defineEmits<{
  (e: "update:visible", visible: boolean): void;
}>();

const props = withDefaults(
  defineProps<{
    visible: boolean;
  }>(),
  {
    visible: false,
  }
);

const showDialog = computed({
  get(): boolean {
    return props.visible;
  },
  set(visible: boolean) {
    emitVisible("update:visible", visible);
  },
});

const close = () => {
  showDialog.value = false;
};
</script>

<template>
  <BaseDialog
    v-model:visible="showDialog"
    :deactivate-close-by-outside-click="false"
    width="420"
  >
    <template #title>
      <div class="model-title">AIで予測する</div>
    </template>
    <template #content>
      <div class="model-content">
        <p class="model-content-title">
          AIを使って新しいデータの予測をすることができます
        </p>
        <div class="model-content-btn">
          <router-link to="/models/list/favorited">
            <BaseBtn
              primary
              width="300"
              height="40"
              active-class="no-active"
              class="wrap-btn-width"
              @click="close"
            >
              お気に入りのAIを使う
            </BaseBtn>
          </router-link>
        </div>
        <div class="model-content-btn">
          <router-link to="/models/list/created">
            <BaseBtn
              primary
              width="300"
              height="40"
              active-class="no-active"
              class="wrap-btn-width"
              @click="close"
            >
              自分で作成したAIを使う
            </BaseBtn>
          </router-link>
        </div>
      </div>
    </template>
    <template #controls>
      <div class="btn-cancel">
        <BaseBtn inverse @click="close"> キャンセル </BaseBtn>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.model-content {
  padding-top: $g-size-24;

  &-title {
    margin: 0;
  }

  &-btn {
    padding-top: $g-size-24;
    display: flex;
    justify-content: center;
  }
}

.btn-cancel {
  padding: $g-size-16 0 $g-size-8 0;
}

.no-active::before {
  opacity: 0 !important;
}
</style>
