<script setup lang="ts">
import { computed, nextTick, ref, watchEffect } from "vue";
import {
  ClassString,
  defaultClass,
} from "@/components/base/BaseDialog/BaseDialog";
import { useControlScrollBar } from "@/hooks/app/useControlScrollBar";

const animatedClass = ref("");

const props = withDefaults(
  defineProps<{
    visible: boolean;
    contentClass?: ClassString[];
    deactivateCloseByOutsideClick?: boolean;
    width?: string | number;
    maxWidth?: string | number;
  }>(),
  {
    visible: false,
    contentClass: () => [],
    deactivateCloseByOutsideClick: true,
    width: "auto",
    maxWidth: "none",
  }
);

const emit = defineEmits<{
  (e: "update:visible", visible: boolean): void;
}>();

const showDialog = computed({
  get(): boolean {
    return props.visible;
  },
  set(visible: boolean) {
    emit("update:visible", visible);
  },
});

const style = computed(() => {
  let style = {};

  if (props.width) {
    style = {
      ...style,
      width: `${props.width}px`,
    };
  }

  return style;
});

const clickedDialogOutside = () =>
  props.deactivateCloseByOutsideClick
    ? nextTick(() => {
        animatedClass.value = "base-dialog--animated";
        window.clearTimeout(-1);
        window.setTimeout(() => (animatedClass.value = ""), 150);
      })
    : (showDialog.value = false);

const concatDefaultClass = computed(() =>
  defaultClass.concat(props.contentClass)
);
const buildContentClass = computed(
  (): ClassString => concatDefaultClass.value.join(" ")
);

// To prevent pages from scrolling when a dialog is open
const rootContainerElement = document.getElementById("root-container"); // HTMLElement
watchEffect((onInvalidate) => {
  useControlScrollBar(onInvalidate, showDialog.value, rootContainerElement);
});
</script>

<template>
  <teleport to="#dialog-container">
    <transition name="dialog" appear>
      <div v-if="showDialog">
        <div
          id="base-dialog"
          :width="width"
          :max-width="maxWidth"
          class="base-dialog"
          :class="[animatedClass, buildContentClass]"
        >
          <div class="base-dialog__overlay">
            <div
              v-click-outside="clickedDialogOutside"
              class="base-dialog__container"
              :style="style"
            >
              <div
                v-if="$slots.title"
                id="base-dialog-title"
                class="base-dialog__container-title"
              >
                <slot name="title" />
              </div>
              <div
                v-if="$slots.content"
                id="base-dialog-content"
                class="base-dialog__container-content"
              >
                <slot name="content" />
              </div>
              <div
                v-if="$slots.controls"
                id="base-dialog-controls"
                class="base-dialog__container-controls"
              >
                <slot name="controls" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<style lang="scss" scoped>
// ref https://github.com/vuetifyjs/vuetify/tree/master/packages/vuetify/src/components/VDialog

$fast-in-fast-out: cubic-bezier(0.25, 0.8, 0.25, 1);

.base-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: 0.3s $fast-in-fast-out;
  z-index: 2;

  &__overlay {
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 10px 13px -6px #0003, 0 20px 31px 3px #00000024,
      0 8px 38px 7px #0000001f;
  }

  &__container {
    background-color: $white-color;
    background-color: #fff;
    padding: $g-size-16 $g-size-12 $g-size-8;
    border-radius: $g-size-4;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    letter-spacing: 0.0071428571em;

    &-title {
      @include heading-1;

      text-align: center;
      padding-top: $g-size-8;
    }

    &-content {
      color: $font-color-main;
      padding: $g-size-16;
    }

    &-controls {
      display: flex;
      justify-content: center;
      padding: $g-size-8 0;
    }
  }
}

.base-dialog--animated {
  animation-duration: 0.15s;
  animation-name: animate-dialog;
  animation-timing-function: $fast-in-fast-out;
}

@keyframes animate-dialog {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(1);
  }
}

.dialog {
  &-enter-active,
  &-leave-active {
    pointer-events: none !important;
  }

  &-enter,
  &-leave-to {
    transform: scale(0.5);
    opacity: 0;
  }

  &-enter-to,
  &-leave {
    opacity: 1;
  }
}
</style>
