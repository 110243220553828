/**
 * FIXME: rewirte with pinia
 */
import { reactive } from "vue";
import { IBackendError, IError } from "@/interfaces/error";
import { ErrorHandler } from "@/error/handler";

export interface ErrorState {
  error: IError;
  showDialog: boolean;
}

export const makeDefaultState = () => ({
  error: {},
  showDialog: false,
});

const state: ErrorState = reactive<ErrorState>(makeDefaultState());

const getters = {
  get error(): IError {
    return state.error;
  },
  get shouldShow(): boolean {
    return state.showDialog;
  },
};

const mutations = {
  setError({ error }: { error: IError }) {
    state.error = error;
  },
  setDialogVisibility({ showDialog }: { showDialog: boolean }) {
    state.showDialog = showDialog;
  },
};

const actions = {
  handleBackendError({ backendError }: { backendError: IBackendError }) {
    const processedError = ErrorHandler.forBackend({
      error: backendError,
    });

    mutations.setError({ error: processedError });
    this.handleDialogVisibility({ showDialog: true });

    console.log(
      `[ui/errorHandler] actions.handleBackendError: mutated`,
      state.error
    );
  },
  handleFrontendError({ frontendError }: { frontendError: IError }) {
    const processedError = ErrorHandler.forFrontend({ error: frontendError });

    mutations.setError({ error: processedError });
    this.handleDialogVisibility({ showDialog: true });

    console.log(
      `[ui/errorHandler] actions.handleFrontendError: mutated`,
      state.error
    );
  },
  handleDialogVisibility({ showDialog }: { showDialog: boolean }) {
    mutations.setDialogVisibility({ showDialog });

    console.log(
      `[ui/errorHandler] actions.handleDialogVisibility: mutated`,
      state.showDialog
    );
  },
};

export const errorHandlerModule = {
  actions,
  getters,
  mutations,
};
