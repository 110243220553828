import { IError } from "@/error/types";
import { StaticImplements } from "@/decorators";

interface IErrorFactory {
  createUnexpectedBase(): IError;
  createNetworkBase(): IError;
  createBackendBase(): IError;
  createPageBasedBase({ error }: { error: IError }): IError;
}

@StaticImplements<IErrorFactory>()
export class ErrorFactory {
  // NOTE: 改修する前にあったコメントをそのまま移植した。
  // not an error from the backend, a network
  // problem on the way to the servers maybe. This may be
  // solved by trying again later
  /** Unexpectedエラーのベース */
  static createUnexpectedBase(): IError {
    return {
      title: "ネットワークエラーが発生しました",
      actionMessage: "少し時間を空けてから処理をやり直してください。",
      actions: ["解決しない場合はdatagustoに連絡してください。"],
    };
  }
  // NOTE: 改修する前にあったコメントをそのまま移植した。
  // Either the request was made and no response was received
  // or the request did not even fire; it is a network issue
  /** ネットワークエラーのベース */
  static createNetworkBase(): IError {
    return {
      title: "ネットワーク接続に失敗しました",
      actionMessage:
        "通信に失敗しました。インターネットの接続を確認し、ブラウザを更新してください。解決しない場合は右上のヘルプメニューよりdatagustoに問い合わせてください。",
      actions: [],
    };
  }

  /** backend（API）エラーのベース */
  static createBackendBase(): IError {
    return {
      title: "エラーが発生しました",
      actionMessage:
        "以下のメッセージを確認して再度実行してください。問題が解決されない場合はdatagustoまでお問い合わせください。",
      actions: [],
      dismissMessage: "戻る",
      isDefinedError: true, // まず、既知のエラーだと仮定
    };
  }

  /**
   * ページ別エラーのベース
   * 基本的に、ページ別エラーの場合は既にエラーが組み上がっている状態で
   * そこに対してエラーを追加していく形になるので、引数を持つ。
   * */
  static createPageBasedBase({ error }: { error: IError }): IError {
    return {
      ...error,
      title: "エラーが発生しました",
      isDefinedError: false, // 基本的に未知のエラー=false しかこないはずだが、ファクトリからは常にfalseを返して欲しい点を明記
    };
  }
}
