import AxiosWrapper from "@/api/axios-wrapper";

import { apiUrl } from "@/env";
import { IFlowResponse } from "@/interfaces/flow";

export const flow = {
  async createFlow() {
    const data = {};
    return AxiosWrapper.post<IFlowResponse>(`${apiUrl}/api/v2/flows/`, data);
  },
};
