import {
  IAuthTokenRepository,
  AuthToken,
} from "./AuthTokenRepositoryInterface";

class AuthTokenCookieRepository implements IAuthTokenRepository {
  get(): string {
    throw new Error("Method not implemented.");
  }

  getOrNull(): string | null {
    throw new Error("Method not implemented.");
  }

  store(t: AuthToken) {
    document.cookie = `token=${t}; path=/`;
  }

  remove() {
    document.cookie = `token=; path=/; max-age=0`;
  }
}

export { AuthTokenCookieRepository };
