import { defineStore, acceptHMRUpdate } from "pinia";
import { api } from "@/api";
import { IFlow } from "@/interfaces/flow";
import { useErrorStore } from "@/store/error";
interface State {
  flow: IFlow | null;
}

const id = "flow";
export const useFlowStore = defineStore({
  id,
  state: (): State => ({
    flow: null,
  }),
  actions: {
    async createFlow() {
      const errorStore = useErrorStore();

      try {
        const response = await api.v2.flow.createFlow();
        if (response) {
          this.$patch({
            flow: response.data.flow,
          });
        }
      } catch (error) {
        errorStore.handleApiError(error);
      }
    },
  },
});

// enable vite HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFlowStore, import.meta.hot));
}
