export type AuthToken = string;

export const authTokenStoreKey = "token";

export interface IAuthTokenRepository {
  get(): AuthToken;
  store(t: AuthToken): void;
  remove(): void;
  getOrNull(): string | null;
}
