<script setup lang="ts">
import { computed, ref } from "vue";
import CheckUserLicenseConnected from "@/components/common/CheckUserLicense/CheckUserLicenseConnected.vue";
import { useCreateFlow } from "@/hooks//flow/useCreateFlow";
import { CONFIG } from "@/config/index";
import { LinkItem } from "./MainSideBarTypes";
import BaseBtn from "@/components/base/BaseButton/BaseButton.vue";
import LinkGroup from "@/components/layouts/sidebar/MainSidebarLinkGroup.vue";
import CreateAiGatewayDialog from "@/components/layouts/CreateAiGatewayDialog.vue";
import PredictWithAiGatewayDialog from "@/components/layouts/PredictWithAiGatewayDialog.vue";
import { useI18n } from "vue-i18n";
// assets
import HomeIcon from "@/assets/home.svg";
import FavoritedModelIcon from "@/assets/favorited-model.svg";
import CreatedModelIcon from "@/assets/created-model.svg";
import CreatedRecipeIcon from "@/assets/created-recipe.svg";
import FindRecipeIcon from "@/assets/search-recipe.svg";
import CreateAiFromDataIcon from "@/assets/create-ai-from-data.svg";
import HelpIcon from "@/assets/help.svg";
import { useViteMeta } from "@/hooks/app/useViteMeta";
import FolderIcon from "@/assets/folder.svg";
import DatasetsIcon from "@/assets/datasets.svg";

const { isDev } = useViteMeta();

const { t } = useI18n();

const { createFlow } = useCreateFlow();

const isVisibleCreateAiDialog = ref<boolean>(false);
const isVisiblePredictWithAiDialog = ref<boolean>(false);

const showCreateAiDialog = () => (isVisibleCreateAiDialog.value = true);
const showPredictWithAiDialog = () =>
  (isVisiblePredictWithAiDialog.value = true);

const home: LinkItem[] = [
  {
    title: t("layouts.sidebar.home"),
    icon: HomeIcon,
    href: "/",
    activatedRegExp: new RegExp(/^\/$/),
  },
];

const modelRelated = computed((): LinkItem[] => [
  {
    title: t("layouts.sidebar.favorite_ai"),
    icon: FavoritedModelIcon,
    href: "/models/list/favorited",
    activatedRegExp: new RegExp(/\/models\/list\/favorited/),
  },
  {
    title: t("layouts.sidebar.created_ai"),
    icon: CreatedModelIcon,
    href: "/models/list/created",
    activatedRegExp: new RegExp(/\/models\/list\/created/),
  },
  {
    title: t("layouts.sidebar.folder"),
    icon: FolderIcon,
    href: "/folders/root/contents",
    activatedRegExp: new RegExp(/\/folders\/root\/contents/),
  },
]);

const recipeRelated = computed((): LinkItem[] => [
  {
    title: t("layouts.sidebar.created_recipe"),
    icon: CreatedRecipeIcon,
    href: "/my-recipes",
    activatedRegExp: new RegExp(/\/my-recipes/),
  },
  {
    title: t("layouts.sidebar.find_recipe"),
    icon: FindRecipeIcon,
    href: "/recipes",
    activatedRegExp: new RegExp(/\/recipes/),
  },
]);

const datasetRelated: LinkItem[] = [
  {
    title: t("layouts.sidebar.create_ai_from_data"),
    icon: CreateAiFromDataIcon,
    onRouteClick: () => onClickCreateAiFromData(),
    activatedRegExp: new RegExp(/\/create-ai\/from-data/),
  },
  {
    title: t("layouts.sidebar.dataset"),
    icon: DatasetsIcon,
    href: "/dataset-queries",
    activatedRegExp: new RegExp(/\/dataset-queries/),
  },
];

const howToUse: LinkItem[] = [
  {
    title: t("layouts.sidebar.how_to_use"),
    icon: HelpIcon,
    onRouteClick: () => undefined, // just open dialog
  },
];

const onClickCreateAiFromData = async () => {
  await createFlow();
};

const handleOnClickHowToUse = () => {
  const url = CONFIG.LINKS.CUSTOMER_RELATIONS.NOTION.MANUAL.LINK;

  window.open(url, "_blank");
};
</script>

<template>
  <div class="main-sidebar">
    <div class="main-sidebar__section">
      <CheckUserLicenseConnected
        v-slot="{ isPermitted }"
        :required-permissions="{ createAi: [`read`, `create`, `update`] }"
      >
        <BaseBtn
          v-if="isPermitted"
          id="create-ai-btn--open-dialog"
          inverse
          :height="39"
          :width="208"
          style="margin-bottom: 16px"
          @click="showCreateAiDialog"
        >
          {{ $t("layouts.sidebar.create_ai") }}
        </BaseBtn>
      </CheckUserLicenseConnected>
      <BaseBtn
        inverse
        :height="39"
        :width="208"
        @click="showPredictWithAiDialog"
      >
        {{ $t("layouts.sidebar.predict_with_ai") }}
      </BaseBtn>
    </div>

    <div class="main-sidebar__section">
      <!-- home-related links -->
      <LinkGroup :link-items="home" class="link-group" />
      <hr class="horizon" />
      <!-- モデル関連 | model-related links -->
      <LinkGroup :link-items="modelRelated" class="link-group" />
      <hr class="horizon" />
      <!-- レシピ関連 | recipe-related links -->
      <LinkGroup :link-items="recipeRelated" class="link-group" />
      <hr class="horizon" />
      <!-- データから作る | ai-from-data -->
      <CheckUserLicenseConnected
        v-slot="{ isPermitted }"
        :required-permissions="{ createAi: [`read`, `create`, `update`] }"
      >
        <!-- AI作成・データ関連 | dataset-related links -->
        <LinkGroup
          v-if="isPermitted"
          :link-items="datasetRelated"
          class="link-group"
        />
        <hr v-if="isPermitted" class="horizon" />
      </CheckUserLicenseConnected>
      <!-- 使い方 | how-to-use -->
      <LinkGroup
        :link-items="howToUse"
        class="link-group"
        @click="handleOnClickHowToUse"
      />
      <template v-if="isDev">
        <!-- dev環境のみデバッグ用リンク | dev env only debug links -->
        (Dev Only) Debug links
        <a href="/sample">
          <div>[Vue2] Sample href</div>
        </a>
        <a href="/class-component">
          <div>[Vue2] class-component href</div>
        </a>
      </template>
    </div>

    <!-- dialogs -->
    <CreateAiGatewayDialog v-model:visible="isVisibleCreateAiDialog" />
    <PredictWithAiGatewayDialog
      v-model:visible="isVisiblePredictWithAiDialog"
    />
  </div>
</template>

<style lang="scss" scoped>
.main-sidebar {
  border-radius: 0 $g-size-20 $g-size-20 0;
  background-color: $blue-light-color;
  height: 100vh;
  width: 250px;
  min-width: 250px;

  &__section {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    padding: 0 $g-size-20;
    align-items: center;
    margin-top: $g-size-32;
  }

  &__shortcut-btn-top {
    margin-bottom: $g-size-16;
  }
}

.link-group {
  padding: $g-size-24 0;
}

.horizon {
  background-color: $separator-color;
  border: none;
  height: 1px;
  width: 100%;
}
</style>
