import {
  state as authState,
  login,
} from "@/CrossCuttingConcern/Authentication/Services/auth0/ClientWrapper";
import { watch, ref } from "vue";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { ICheckUserAuth } from "@/CrossCuttingConcern/Authentication/Middleware/CheckUserAuthInterface";

export const checkUserAuthWithAuth0: ICheckUserAuth = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
): void => {
  const guardAction = () => {
    if (authState.isAuthenticated) {
      return next();
    }

    login({
      appState: {
        targetUrl: to.fullPath,
      },
    });
  };

  if (!authState.isLoading) {
    return guardAction();
  }

  watch(ref(authState.isLoading), (currentValue) => {
    if (!currentValue) {
      return guardAction();
    }
  });
};
