import { IBackendError, IError } from "@/error/types";
import { RouteLocationNormalizedLoaded } from "vue-router";
import { StaticImplements } from "@/decorators";
import {
  FrontendErrorProcessor,
  BackendErrorProcessor,
  PageBasedErrorProcessor,
} from "./processors/index";

/** エラーハンドラ層 */
interface IErrorHandler {
  forFrontend({ error }: { error: IError }): IError;
  forBackend({ error }: { error: IBackendError }): IError;
  forPageBased({
    error,
    opts,
  }: {
    error: IBackendError | IError;
    opts?: { route: RouteLocationNormalizedLoaded };
  }): IError;
}

/**
 * NOTE: エラーハンドラ層
 *
 * 「どの経路のエラーか？」を振り分けられるようFactory Methodでの実装でエラーハンドラとしての
 * 口を表現。エラーハンドラのファサードとして活用してもいい。
 * */
@StaticImplements<IErrorHandler>()
export class ErrorHandler {
  /**
   * フロントエンドのエラー
   * 主にフロントエンドが起因で起こったエラーをハンドルする経路を提供
   */
  public static forFrontend({ error }: { error: IError }): IError {
    return new FrontendErrorProcessor().process({ error });
  }

  /**
   * バックエンド（API）のエラー
   * 主にサーバーサイドが起因で起こったエラーをハンドルする経路を提供
   */
  public static forBackend({ error }: { error: IBackendError }): IError {
    return new BackendErrorProcessor().process({ error });
  }

  /**
   * ページごとエラー
   * ページごとでエラーをUI上に表示したいというユースケースで利用する経路を提供
   */
  public static forPageBased({
    error,
    opts,
  }: {
    error: IError;
    opts: { route: RouteLocationNormalizedLoaded };
  }): IError {
    if (error.isDefinedError) {
      return error;
    } else {
      return new PageBasedErrorProcessor().process({
        error,
        opts,
      });
    }
  }
}
