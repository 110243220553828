import { createI18n } from "vue-i18n";
import { messages } from "vite-i18n-resources";

export enum LocaleTypes {
  JP = "jp",
  EN = "en",
}

const vuei18n = createI18n({
  locale: LocaleTypes.JP,
  messages,
  fallbackLocale: LocaleTypes.JP,
});

// enable hot reload for local json
if (import.meta.hot) {
  import.meta.hot.on("locales-update", (data: any) => {
    Object.keys(data).forEach((lang) => {
      vuei18n.global.setLocaleMessage(lang, data[lang]);
    });
  });
}

export default vuei18n;
