<script setup lang="ts">
import { useAttrs, ref } from "vue";
import Primary from "@/components/base/BaseButton/Variants/Primary.vue";
import Inverse from "@/components/base/BaseButton/Variants/Inverse.vue";
import Text from "@/components/base/BaseButton/Variants/Text.vue";

const attrs = useAttrs();

withDefaults(
  defineProps<{
    exact?: boolean;
    disabled?: boolean;
    height?: number | string;
    width?: number | string;
  }>(),
  {
    exact: true,
    disabled: false,
    height: undefined,
    width: undefined,
  }
);

defineEmits<{
  (e: "click"): void;
}>();

const isPrimary = ref<boolean>(attrs.primary !== undefined);
const isInverse = ref<boolean>(attrs.inverse !== undefined);
const isText = ref<boolean>(attrs.text !== undefined);
</script>

<template>
  <Primary
    v-if="isPrimary"
    :class="$attrs.class"
    :style="$attrs.style"
    v-bind="$props"
    @click="$emit('click')"
  >
    <slot />
  </Primary>
  <Inverse
    v-else-if="isInverse"
    :class="$attrs.class"
    :style="$attrs.style"
    v-bind="$props"
    @click="$emit('click')"
  >
    <slot />
  </Inverse>
  <Text
    v-else-if="isText"
    :class="$attrs.class"
    :style="$attrs.style"
    v-bind="$props"
    @click="$emit('click')"
  >
    <slot />
  </Text>
  <!-- fallback -->
  <Primary
    v-else
    :class="$attrs.class"
    :style="$attrs.style"
    v-bind="$props"
    @click="$emit('click')"
  >
    <slot />
  </Primary>
</template>
