<script setup lang="ts">
import { ref } from "vue";

const isShown = ref<boolean>(false);

const toggleHandler = () => (isShown.value = !isShown.value);
const handleClose = () => (isShown.value = false);
</script>

<template>
  <div v-click-outside="handleClose" class="dropdown">
    <slot name="activator" :toggle="toggleHandler" />
    <div v-show="isShown" class="dropdown-content">
      <slot name="content" :toggle="toggleHandler" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.dropdown {
  position: relative;
  display: inline-block;

  &-content {
    display: block;
    position: absolute;
    background-color: $white;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: $dropdown-z-index;
    right: 0px;
    width: 300px;
    padding: $g-size-8;
    border-radius: 10px;
  }
}
</style>
