import { AuthTokenCookieRepository } from "@/CrossCuttingConcern/Authentication/Repositories/AuthTokenInCookieRepository";
import { AuthTokenLocalStorageRepository } from "@/CrossCuttingConcern/Authentication/Repositories/AuthTokenInLocalStorageRepository";
import { AuthToken } from "@/CrossCuttingConcern/Authentication/Repositories/AuthTokenRepositoryInterface";

export class AuthTokenHandler {
  private onCookieRepo: AuthTokenCookieRepository;
  private onLocalStorageRepo: AuthTokenLocalStorageRepository;

  constructor() {
    this.onCookieRepo = new AuthTokenCookieRepository();
    this.onLocalStorageRepo = new AuthTokenLocalStorageRepository();
  }

  get localStorageRepository() {
    return new AuthTokenLocalStorageRepository();
  }

  get hasToken() {
    return this.onLocalStorageRepo.get() !== "";
  }

  store(t: AuthToken) {
    this.onCookieRepo.store(t);
    this.onLocalStorageRepo.store(t);
  }

  remove() {
    this.onCookieRepo.remove();
    this.onLocalStorageRepo.remove();
  }
}
