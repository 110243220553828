<script setup lang="ts">
export type LinkItem = {
  label: string;
  onClick?: () => void;
  href?: string;
};

export type LinkItemWithCategory = {
  category: {
    label: string;
  };
  items: LinkItem[];
};

type Props = {
  itemList: LinkItemWithCategory[];
};

defineProps<Props>();

const emit = defineEmits<{
  (e: "click"): void;
}>();

const renderCallbackForOnClick = (link: LinkItem) => {
  if (link.onClick) {
    link.onClick();
  }
  emit("click");
};
</script>

<template>
  <div v-for="(item, itemIdx) in itemList" :key="itemIdx">
    <div class="dropdown-content__category-label">
      {{ item.category.label }}
    </div>
    <div
      v-for="(link, linkIdx) in item.items"
      :key="linkIdx"
      class="dropdown-content__chunk"
    >
      <router-link v-if="link.href" :to="link.href">
        <div
          v-ripple
          class="dropdown-content__chunk-row"
          @click="$emit('click')"
        >
          {{ link.label }}
        </div>
      </router-link>
      <div
        v-else-if="link.onClick"
        v-ripple
        class="dropdown-content__chunk-row"
        @click="renderCallbackForOnClick(link)"
      >
        {{ link.label }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
}

.dropdown-content {
  &__category {
    &-label {
      padding: $g-size-8;
      border-radius: $g-size-4;
      font-family: $font-noto-sans-jp;
      font-size: 12px;
      color: $font-color-note;
    }
  }
  &__chunk {
    &-row {
      padding: $g-size-8;
      cursor: pointer;
      border-radius: $g-size-4;
      font-family: $font-noto-sans-jp;
      font-size: 14px;
      color: $font-color-main;

      &:hover {
        background: $hover-on-white;
      }
    }
  }
}
</style>
