import "vuetify/styles";
import "@mdi/font/css/materialdesignicons.css";
import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import { getPlugin } from "@/CrossCuttingConcern/Authentication/Services/auth0/ClientWrapper";
import { createPinia } from "pinia";
import { vuetifyPlugin } from "@/plugins/vuetify";
import vuei18n from "@/locales";
import { useInitSentry } from "@/hooks/providers/Sentry/useSentry";
import { Integrations } from "@sentry/tracing";
import { apiUrl } from "@/env";
import "@/styles/font-famly.css";

const pinia = createPinia();

export const app = createApp(App);

export const sentryOpts = {
  app,
  environment: import.meta.env.VITE_APP_ENV,
  release: `${import.meta.env.VITE_APP_NAME}@${
    import.meta.env.VITE_APP_VERSION
  }`,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [apiUrl],
    }),
  ],
  debug: false,
  tracesSampleRate: import.meta.env.VITE_APP_ENV === "production" ? 0.2 : 1,
  tracingOptions: {
    trackComponents: true,
  },
  logErrors: import.meta.env.VITE_APP_ENV === "production" ? false : true,
  attachProps: true,
  attachStacktrace: true,
};

if (import.meta.env.VITE_APP_SENTRY_DSN !== "") {
  useInitSentry();
}
app.use(router);
app.use(getPlugin);
app.use(pinia);
app.use(vuetifyPlugin);
app.use(vuei18n);

app.mount("#icestark-main");
