// Styles
import * as directives from "vuetify/directives";
import { createVuetify } from "vuetify";

export const datagustoLight = {
  dark: false,
  colors: {
    primary: "#ee5533",
    secondary: "#FAFAFA",
    error: "#B10036",
    brandSub: "#ff9408",
    warning: "#f83333",
  },
  options: {
    customProperties: true,
  },
};

export const vuetifyPlugin = createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    theme: {
      defaultTheme: "datagustoLight",
      themes: {
        datagustoLight,
      },
    },
    // MEMO: We do not use vuetify components, only use vuetify directives.
    directives,
  }
);
