import { IError, IBackendError } from "@/error/types";
import { IErrorProcessor } from "../interface";
import { ErrorFactory } from "@/error/factory";
import { ErrorCode } from "@/error/types";

export class BackendErrorProcessor implements IErrorProcessor {
  process({ error }: { error: IBackendError }): IError {
    return convertToError({ apiError: error });
  }
}

/**
 * DDDでいう腐敗防止層にあたる（と思う）
 * バックエンドから返却されたエラーをそのまま利用できないため、
 * IBackendError -> IErrorへの変換を行う。
 */
function convertToError({ apiError }: { apiError: IBackendError }): IError {
  const error = ErrorFactory.createBackendBase();

  switch (apiError.code) {
    case ErrorCode.ResourceNotFound:
      for (const target of apiError.target || []) {
        error.actions?.push(target + " はシステムに存在していません。");
      }
      break;

    case ErrorCode.NoDuplicateAllowed:
      for (const target of apiError.target || []) {
        error.actions?.push(
          target + " はすでにシステムに登録されています。重複できません。"
        );
      }
      break;

    case ErrorCode.BadArguments:
      for (const target of apiError.target || []) {
        error.actions?.push(target + " の内容に不備がないか確認してください。");
      }
      break;

    case ErrorCode.ExceedMaxSavedPredictions:
      error.actions?.push("結果を保存できる上限を超えています。");
      break;

    case ErrorCode.MissingColumns:
      error.actionMessage =
        "アップロードされたデータに必要なデータがありません。";
      for (const target of apiError.target || []) {
        error.actions?.push(`${target} が含まれているか確認してください。`);
      }
      break;

    case ErrorCode.SimulationFlowTime:
      error.actions?.push(
        "入力データの保存期間がすぎているため、シミュレーション画面を開けません。"
      );
      break;

    case ErrorCode.InactiveUser:
      error.title = "ログインの有効期限が切れました";
      error.actionMessage = "再度ログインしてください。";
      break;

    case ErrorCode.NotAuthorized:
      error.title = "この操作を実行する権限がありません";
      error.actionMessage = "管理者に連絡して, 確認してください。";
      break;

    case ErrorCode.UnsupportedFileExtension:
      error.title = "アップロードされたファイルを読み込めません";
      error.actions?.push("対応したファイル拡張子かを確認してください。");
      break;

    case ErrorCode.ExceedMaxActiveUsers:
      error.title = "ライセンス数の限度に達しています";
      error.actionMessage = "他ユーザーのアカウントを無効化してください。";
      break;

    case ErrorCode.NotEnoughDataSize:
      error.title = "データが少ないため学習できませんでした";
      error.actionMessage =
        "学習に利用できるデータを少なくとも10件以上用意してください。";
      error.actions?.push(
        "予測したい変数が空欄でない行が10件以上あることを確認してください。"
      );
      error.actions?.push(
        "予測したい変数のデータ型を変更した場合（例：カテゴリ→数値）、変更できないデータは空欄となるため、空欄でない行が10行未満となっている可能性があります。"
      );
      break;

    case ErrorCode.GoogleAuthRequired:
      error.title = "Googleログインが必要です";
      error.actionMessage =
        "Googleからログアウトしたか、ログインの有効期限が切れています。Googleにログインしてください。";
      break;

    case ErrorCode.GooglePremissionRequired:
      error.title = "Googleへのアクセス権限が不十分です";
      error.actionMessage =
        "再度Googleにログインし、以下のアクセス権限を付与してください。";
      error.actions?.push(
        "Google ドライブのすべてのファイルの表示、編集、作成、削除"
      );
      error.actions?.push(
        "Google スプレッドシートのすべてのスプレッドシートの参照、編集、作成、削除"
      );
      break;

    case ErrorCode.InvalidGoogleSheetURL:
      error.title = "URLが正しくありません";
      error.actionMessage =
        "Google スプレッドシートの URL を指定してください。";
      break;

    case ErrorCode.GoogleSheetNotFound:
      error.title = "Google スプレッドシートが見つかりませんでした";
      error.actionMessage =
        "指定されたスプレッドシートは存在しないか、閲覧権限がありません。";
      error.actions?.push("URLが正しいことを確認してください。");
      error.actions?.push("スプレッドシートの共有設定を確認してください。");
      break;

    case ErrorCode.FileIsNotGoogleSheet:
      error.title = "スプレッドシートの読み込みに失敗しました";
      error.actionMessage =
        "指定されたファイルはスプレッドシートではありません。";
      error.actions?.push("スプレッドシートの URL を指定してください。");
      error.actions?.push(
        ".xlsx, .xls などの Excel ファイルを読み込むことはできません。Google スプレッドシートに変換してください。"
      );
      break;

    default:
      // 変換不可能な場合を未知のエラーとしている
      error.isDefinedError = false;
      break;
  }

  return error;
}
