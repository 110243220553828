<script setup lang="ts">
import LinkWithLeftChevron from "@/components/common/LinkWithLeftChevron/LinkWithLeftChevron.vue";
import BaseBtn from "@/components/base/BaseButton/BaseButton.vue";
import { CONFIG } from "@/config/index";

const handleGoToHome = () => location.assign("/");
const handleGoBackPage = () => window.history.back();
const handleGoToContactPage = () =>
  window.open(CONFIG.LINKS.CUSTOMER_RELATIONS.ZENDESK.CONTACT.LINK, "_blank");
</script>

<template>
  <div id="not-found-page">
    <div class="header">
      <img src="@/assets/created-ai-on-dashboard.svg" height="30" width="30" />
      <h1 class="header-title">ページが見つかりません</h1>
    </div>
    <div class="content">
      <div class="content__label">お探しのページが見つかりませんでした。</div>
      <div class="content__action">
        <div class="content__action--left">
          <BaseBtn primary @click="handleGoToHome"> ホームに戻る </BaseBtn>
        </div>
        <div class="content__action--right">
          <BaseBtn inverse @click="handleGoToContactPage">
            お問い合わせはこちら
          </BaseBtn>
        </div>
      </div>
      <LinkWithLeftChevron label="戻る" @click="handleGoBackPage" />
    </div>
    <div class="visual">
      <img class="logo" src="@/assets/le-pot.svg" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
#not-found-page {
  margin: 0 $g-size-24;
  .header {
    display: flex;
    margin-bottom: 20px;

    &-title {
      color: $font-color-main;
      font-weight: $font-weight-500;
      font-size: $font-size-20;
      padding-left: $g-size-16;
    }
  }

  .content {
    z-index: 100;
    background-color: white;

    &__label {
      margin-bottom: $g-size-32;
    }

    &__action {
      display: flex;

      &--left {
        margin-bottom: 32px;
        margin-right: 12px;
      }
      &--right {
        margin-bottom: 32px;
      }
    }
  }

  .visual {
    display: flex;
    width: 75%;
    position: absolute;
    right: -18vw;
    bottom: 7vh;
  }

  .logo {
    width: 100vw;
    height: auto;
    object-fit: cover;
    object-position: 0 100%;
  }
}
</style>
