import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { AuthTokenLocalStorageRepository } from "@/CrossCuttingConcern/Authentication/Repositories/AuthTokenInLocalStorageRepository";

type AuthHeader = Record<string, string>;

const repo = new AuthTokenLocalStorageRepository();
const token = repo.get();

export function authHeader(token: string): AuthHeader {
  return { Authorization: `Bearer ${token}` };
}

const instance: AxiosInstance = axios.create();

/**
 * sample below
 * @see https://qiita.com/shts/items/e15ab37dff40df73048c
 */
instance.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers = authHeader(token);

  return config;
});

export default instance;
