<script setup lang="ts">
import { LinkItem } from "@/components/layouts/sidebar/MainSideBarTypes";
import MainSidebarLinkBase from "@/components/layouts/sidebar/MainSidebarLink/MainSidebarLinkBase.vue";

const props = defineProps<{
  item: LinkItem;
}>();

const linkTo = props.item.href ?? "";

if (linkTo === "") {
  console.error("link is not defined!");
}
</script>

<template>
  <router-link :to="linkTo">
    <MainSidebarLinkBase :item="item" />
  </router-link>
</template>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
}
</style>
