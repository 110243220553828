/**
 * Implemented after below sample auth0 client
 * @see  https://github.com/auth0-developer-hub/spa_vue-3_typescript_hello-world/blob/basic-authentication/src/services/auth0-plugin.ts
 */
import createAuth0Client, {
  Auth0Client,
  GetTokenSilentlyOptions,
  LogoutOptions,
  RedirectLoginOptions,
  User,
} from "@auth0/auth0-spa-js";
import { reactive } from "vue";
import { auth0Domain, auth0ClientId, auth0Audience } from "@/env";
import { AuthTokenHandler } from "@/CrossCuttingConcern/Authentication/Services/AuthTokenHandler";
import { createAuth0 } from "@auth0/auth0-vue";

const callbackUrl = window.location.origin;

const tokenHandler = new AuthTokenHandler();

export const state = reactive<{
  auth0Client: Auth0Client | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  user: User | null;
  error: Error | null;
}>({
  auth0Client: null,
  isAuthenticated: false,
  isLoading: true,
  user: null,
  error: null,
});

/**
 * Create & return plugin for auth0.
 */
export const getPlugin = async () =>
  createAuth0({
    domain: auth0Domain,
    client_id: auth0ClientId,
    audience: auth0Audience,
    redirect_uri: callbackUrl,
  });

/**
 * Create the auth0 client which communicate with auth0.
 */
export const createClient = async (): Promise<void> => {
  state.auth0Client = await createAuth0Client({
    domain: auth0Domain,
    client_id: auth0ClientId,
    audience: auth0Audience,
    redirect_uri: callbackUrl,
  });
};

export const handleCallback = async (): Promise<void> => {
  if (!state.auth0Client) {
    console.error("🚨 auth0Client not set!");

    return;
  }

  state.isAuthenticated = await state.auth0Client.isAuthenticated();

  if (state.isAuthenticated) {
    state.user = (await state.auth0Client.getUser()) || null;
    state.isLoading = false;

    window.history.replaceState({}, document.title, window.location.pathname);

    return;
  }

  const params = new URLSearchParams(window.location.search);
  const hasError = params.has("error");
  const hasCode = params.has("code");
  const hasState = params.has("state");

  if (hasError) {
    state.error = new Error(
      params.get("error_description") || "error completing login process"
    );

    state.isLoading = false;

    return;
  }

  if (hasCode && hasState) {
    try {
      const result = await state.auth0Client.handleRedirectCallback();

      let url = "/";

      if (result.appState && result.appState.targetUrl) {
        url = result.appState.targetUrl;
      }

      state.isAuthenticated = await state.auth0Client.isAuthenticated();

      if (state.isAuthenticated) {
        const token = await getAccessToken({});

        if (token) {
          /**
           * This line only stores token locally, not persist on state
           * because store is not available at auth request.
           *
           * But, it could be OK, because we basically get token from
           * localstorage.
           */
          tokenHandler.store(token);
        } else {
          console.error("token not set!");
        }

        state.user = (await state.auth0Client.getUser()) || null;

        state.error = null;

        state.isLoading = false;

        location.assign(url);

        return;
      }
    } catch (err: any) {
      state.error = err;
    }
  }

  state.isLoading = false;
};

export const login = async (options?: RedirectLoginOptions): Promise<void> => {
  if (!state.auth0Client) {
    return;
  }

  try {
    await state.auth0Client.loginWithRedirect(options);
  } catch (err: any) {
    state.error = err;
  }
};

export const logout = async (options?: LogoutOptions): Promise<void> => {
  if (!state.auth0Client) {
    return;
  }

  try {
    state.auth0Client.logout(options);
  } catch (err: any) {
    state.error = err;
  }
};

export const getAccessToken = async (
  options?: GetTokenSilentlyOptions
): Promise<null | string> => {
  if (!state.auth0Client) {
    return null;
  }

  return (await state.auth0Client.getTokenSilently(options)) as string;
};
