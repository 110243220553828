<script setup lang="ts">
import { computed } from "vue";
import BaseDialog from "@/components/base/BaseDialog/BaseDialog.vue";
import BaseBtn from "@/components/base/BaseButton/BaseButton.vue";
import { useCreateFlow } from "@/hooks/flow/useCreateFlow";

const { createFlow } = useCreateFlow();

const emit = defineEmits<{
  (e: "update:visible", visible: boolean): void;
}>();

const props = withDefaults(
  defineProps<{
    visible: boolean;
  }>(),
  {
    visible: false,
  }
);

const showDialog = computed({
  get(): boolean {
    return props.visible;
  },
  set(visible: boolean) {
    emit("update:visible", visible);
  },
});

const close = () => {
  showDialog.value = false;
};

const handleDialogVisibility = (visibility: boolean) => {
  emit("update:visible", visibility);
};

const onClickCreateAiFromData = async () => {
  close();
  await createFlow();
};
</script>

<template>
  <BaseDialog
    v-model:visible="showDialog"
    :deactivate-close-by-outside-click="false"
    :width="420"
  >
    <template #title>AIを作る</template>
    <template #content>
      <p class="select-cooking-description text-center">
        お好きな方法でAIを作ってみましょう
      </p>
      <div class="wrap-btn">
        <h3 class="wrap-btn-sub-title">新しくAIを作る</h3>
        <router-link to="/recipes">
          <BaseBtn
            primary
            width="300"
            height="40"
            active-class="no-active"
            class="wrap-btn-width"
            @click="close"
          >
            レシピからAIを作る
          </BaseBtn>
        </router-link>
      </div>
      <div class="wrap-btn">
        <BaseBtn
          id="create-ai-from-data-btn"
          primary
          width="300"
          height="40"
          active-class="no-active"
          class="wrap-btn-width"
          @click="onClickCreateAiFromData"
        >
          データからAIを作る
        </BaseBtn>
      </div>
      <div class="wrap-btn">
        <h3 class="wrap-btn-sub-title">既存のAIから作り直す</h3>
        <router-link to="/models/list/favorited">
          <BaseBtn
            primary
            width="300"
            height="40"
            active-class="no-active"
            class="wrap-btn-width"
            @click="close"
          >
            お気に入りのAIをもとに作り直す
          </BaseBtn>
        </router-link>
      </div>
      <div class="wrap-btn">
        <router-link to="/models/list/created">
          <BaseBtn
            primary
            width="300"
            height="40"
            active-class="no-active"
            class="wrap-btn-width"
            @click="close"
          >
            自分で作成したAIをもとに作り直す
          </BaseBtn>
        </router-link>
      </div>
    </template>
    <template #controls>
      <div class="btn-close-section">
        <BaseBtn inverse @click="close" @change="() => handleDialogVisibility">
          キャンセル
        </BaseBtn>
      </div>
    </template>
  </BaseDialog>
</template>

<style scoped lang="scss">
.wrap-btn {
  padding-top: $g-size-24;
  text-align: center;

  &:first-of-type {
    padding-top: $g-size-12;
  }

  &-sub-title {
    display: block;
    margin-bottom: $g-size-16;
    font-weight: 700;
    color: $font-color-main;
  }
}

.select-cooking-description {
  margin: $g-size-8 0 $g-size-12 0;
}

.btn-close-section {
  margin: $g-size-16 0 $g-size-8 0;
}

// Delete below if this style is not required.
.no-active::before {
  opacity: 0 !important;
}
</style>
