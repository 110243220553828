/**
 * [JA]
 * APIをコールして受け取ったバックエンドがもつ例外クラス
 * [EN]
 * given backend's error class.
 */
export interface IBackendError {
  code: string;
  message?: string;
  target?: string[];
  details?: any;
  key?: string;
}

/**
 * [JA]
 * エラーの最終的な生成オブジェクト。これを軸にしてUI上で表示させる
 * [EN]
 * The result of processing an backend and frontend error for ErrorDialog
 */
export interface IError {
  title?: string;
  actionMessage?: string;
  actions?: string[];
  dismissMessage?: string;
  isDefinedError?: boolean;
}

export enum ErrorCode {
  BadArguments = "BadArguments",
  ExceedMaxActiveUsers = "ExceedMaxActiveUsers",
  ExceedMaxSavedPredictions = "ExceedMaxSavedPredictions",
  InactiveUser = "InactiveUser",
  MissingColumns = "MissingColumns",
  NoDuplicateAllowed = "NoDuplicateAllowed",
  NotAuthorized = "NotAuthorized",
  NotEnoughDataSize = "NotEnoughDataSize",
  ResourceNotFound = "ResourceNotFound",
  SimulationFlowTime = "SimulationFlowTime",
  UnknownError = "UnknownError",
  UnsupportedFileExtension = "UnsupportedFileExtension",
  GoogleAuthRequired = "GoogleAuthRequired",
  GooglePremissionRequired = "GooglePremissionRequired",
  InvalidGoogleSheetURL = "InvalidGoogleSheetURL",
  GoogleSheetNotFound = "GoogleSheetNotFound",
  FileIsNotGoogleSheet = "FileIsNotGoogleSheet",
}
