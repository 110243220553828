import {
  IAuthTokenRepository,
  AuthToken,
  authTokenStoreKey,
} from "./AuthTokenRepositoryInterface";

class AuthTokenLocalStorageRepository implements IAuthTokenRepository {
  get(): AuthToken {
    return localStorage.getItem(authTokenStoreKey) ?? "";
  }

  getOrNull(): string | null {
    return localStorage.getItem(authTokenStoreKey);
  }

  store(t: AuthToken) {
    localStorage.setItem(authTokenStoreKey, t);
  }

  remove() {
    localStorage.removeItem(authTokenStoreKey);
  }
}

export { AuthTokenLocalStorageRepository };
