import AxiosWrapper from "@/api/axios-wrapper";
import { apiUrl } from "@/env";
import {
  INumbersOfUsersByEachLicense,
  IUserProfileListReponse,
  UserProfileResponse,
  IUserProfileCreate,
  IUserProfileUpdate,
} from "@/interfaces/auth";

const usersApiUri = `${apiUrl}/api/v2/users`;

export const users = {
  async list() {
    return AxiosWrapper.get<IUserProfileListReponse>(`${usersApiUri}/`);
  },
  async me() {
    return AxiosWrapper.get<UserProfileResponse>(`${usersApiUri}/me`);
  },
  async getById({ userId }: { userId: string }) {
    return AxiosWrapper.get<UserProfileResponse>(`${usersApiUri}/${userId}`);
  },
  async create({ user }: { user: IUserProfileCreate }) {
    return AxiosWrapper.post<UserProfileResponse>(`${usersApiUri}/`, user);
  },
  async updateById({
    userId,
    user,
  }: {
    userId: string;
    user: IUserProfileUpdate;
  }) {
    return AxiosWrapper.put<UserProfileResponse>(
      `${usersApiUri}/${userId}`,
      user
    );
  },
  async updateMe(data: IUserProfileUpdate) {
    return AxiosWrapper.put<UserProfileResponse>(`${usersApiUri}/me`, data);
  },
  async changePassword() {
    return AxiosWrapper.post<UserProfileResponse>(
      `${usersApiUri}/change-password`
    );
  },
  async getNumbersOfActiveUsersByEachLicense() {
    return AxiosWrapper.get<{
      numbers_of_active_users: INumbersOfUsersByEachLicense;
    }>(`${usersApiUri}/count`);
  },
};
