<script lang="ts">
import {
  RequiredPermission,
  checkPermission,
} from "@/services/user-license/UserLicenseSpecification";
import { UserLicenseType } from "@/interfaces/user-license";
import { defineComponent, PropType, computed } from "vue";

export default defineComponent({
  name: "CheckUserLicense",
  functional: true,
  props: {
    userLicenseType: {
      type: String as PropType<UserLicenseType>,
      required: true,
    },
    requiredPermissions: {
      type: Object as PropType<RequiredPermission>,
      required: true,
    },
  },
  setup(props) {
    const isPermitted = computed(() =>
      checkPermission({
        userLicenseType: props.userLicenseType,
        requiredPermissions: props.requiredPermissions,
      })
    );

    return {
      isPermitted,
    };
  },
});
</script>

<template>
  <slot :is-permitted="isPermitted" />
</template>
