/**
 * FIXME: 仕様・APIレスポンスともに未完成だった時に仮置きでフロントエンド側で
 * 仕様に沿ったルールを実装しただけになっている。
 *
 * TODO: 10月リリースに間に合うように、認可チェックによるUIの状態変化については
 * 一旦は表示・非表示似合わせる形になった。最終的には、disabled状態をそれぞれのコンポーネントに作成し
 * disabledの場合はツールチップなどを置かせて、ユーザーになぜ操作不能になっているのか提示したいとのこと。
 *
 * @see https://github.com/datagusto/datagusto.ai/pull/798#issuecomment-920594030
 */
import { UserLicenseType } from "@/interfaces/user-license";

type Scope = "createAi";

export type PermissionFunction = "create" | "delete" | "update" | "read";
export type RequiredPermission = Partial<Record<Scope, PermissionFunction[]>>;

export interface Role {
  scope: Scope;
  name: string;
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

const simulationUserLicensePermission: Role[] = [
  {
    update: false,
    create: false,
    delete: false,
    read: false,
    scope: "createAi",
    name: "AIを作る",
  },
];

const analysisUserLicensePermission: Role[] = [
  {
    update: true,
    create: true,
    delete: true,
    read: true,
    scope: "createAi",
    name: "AIを作る",
  },
];

export class UserLicenseSpecification {
  public getPermissionOf({
    userLicenseType,
  }: {
    userLicenseType: UserLicenseType;
  }) {
    if (userLicenseType === "simulation_user") {
      return simulationUserLicensePermission;
    }
    if (userLicenseType === "analysis_user") {
      return analysisUserLicensePermission;
    }

    return [];
  }
}

export const checkPermission = ({
  userLicenseType,
  requiredPermissions,
}: {
  userLicenseType: UserLicenseType | undefined;
  requiredPermissions: RequiredPermission;
}): boolean => {
  if (!userLicenseType) return false;

  /**
   * TODO: 10月リリーススコープについて、一旦は
   * AIを作成する周りのロールしかなかったので、
   * 一旦、その場合のみをみている。
   *
   * 例えば、createAi以外のスコープも順次追加されていくことが予想される。
   */
  if (requiredPermissions.createAi) {
    const currentPermission = new UserLicenseSpecification().getPermissionOf({
      userLicenseType,
    });

    const requirements = requiredPermissions.createAi;

    const userHasPermission = requirements.every((req: PermissionFunction) => {
      return currentPermission.find((permission) => {
        return permission[req];
      });
    });

    return userHasPermission;
  }

  return true; //TODO: 一旦、AI作成スコープに該当しないものは全てtrueへ
};
