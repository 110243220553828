export const useControlScrollBar = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onInvalidate: any,
  watchValue: unknown,
  targetScrollBarElement: HTMLElement | null
) => {
  if (watchValue) {
    if (!targetScrollBarElement) return;
    useHideScrollBar(targetScrollBarElement);
  }
  onInvalidate(() => {
    if (!targetScrollBarElement) return;
    useShowScrollBar(targetScrollBarElement);
  });
};

const useHideScrollBar = (hideScrollBarElement: HTMLElement) => {
  hideScrollBarElement.style.overflowY = "hidden";
  hideScrollBarElement.style.height = "100vh";
};

const useShowScrollBar = (showScrollBarElement: HTMLElement) => {
  showScrollBarElement.style.overflowY = "";
  showScrollBarElement.style.height = "";
};
