import { useFlowStore } from "@/store/flow";
import { useRouter } from "vue-router";

export const useCreateFlow = () => {
  const store = useFlowStore();
  const router = useRouter();

  async function createFlow(): Promise<void> {
    await store.createFlow();
    const flow = store.flow;
    if (flow === null) {
      console.warn("the flow is null.");
      return;
    }

    await router.push(`/create-ai/from-data/${flow.id}/plan`);
  }

  return { createFlow };
};
