import {
  createRouter,
  createWebHistory,
  RouteLocationNormalized,
  NavigationGuardNext,
} from "vue-router";
import NotFound from "@/pages/errors/NotFound.vue";
import { checkUserAuthWithAuth0 } from "@/CrossCuttingConcern/Authentication/Middleware/CheckUserAuth";
const routerHistory = createWebHistory();

const router = createRouter({
  history: routerHistory,
  routes: [
    {
      path: "/:pathMatch(.*)",
      name: "notfound",
      component: NotFound,
    },
  ],
});

router.beforeEach(
  (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) => {
    checkUserAuthWithAuth0(to, from, next);
  }
);

export default router;
