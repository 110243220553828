<script setup lang="ts">
import { watch, ref } from "vue";
import { LinkItem } from "@/components/layouts/sidebar/MainSideBarTypes";
import { useRoute } from "vue-router";

const isRouteActivatedOnRow = ref(false);

const route = useRoute();
const props = defineProps<{
  item: LinkItem;
}>();

watch(
  () => route.path,
  () => {
    const hasRoutePartialMatch =
      window.location.pathname.match(
        props.item.activatedRegExp ?? new RegExp("/THIS_SHOULD_NOT_MATCH/")
      ) ?? [];

    isRouteActivatedOnRow.value = hasRoutePartialMatch?.length > 0;
  },
  { immediate: true }
);
</script>

<template>
  <div
    class="link-row"
    :class="{ 'link-row--activated': isRouteActivatedOnRow }"
    @click="$emit(`click`, $event)"
  >
    <div class="link-row__icon">
      <img :src="item.icon" />
    </div>
    <div class="link-row__title">
      {{ item.title }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
.link-row {
  min-height: 22px;
  height: 22px;
  display: flex;
  color: $font-color-sub;
  cursor: pointer;

  &__title {
    color: $font-color-sub;
    font-size: $font-size-15;
    font-weight: bold;
  }

  &__icon {
    height: $g-size-20;
    width: $g-size-20;
    margin-right: $g-size-16;
  }

  &--activated {
    background-color: rgba(0, 0, 0, 0.05);
    font-weight: $font-weight-700;
  }
}
</style>
