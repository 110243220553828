import { getOrEmpty, getBoolean } from "./utils/string";

export const apiUrl = getOrEmpty(import.meta.env.VITE_APP_API_URL);
export const apiUrlV2 =
  import.meta.env.VITE_APP_ENV === "development"
    ? getOrEmpty(import.meta.env.VITE_APP_API_URL_V2_MOCK) // [FIXME] delete this if v1→v2 migration finished
    : apiUrl;

export const appName = getOrEmpty(import.meta.env.VITE_APP_NAME);

export const auth0Domain = getOrEmpty(import.meta.env.VITE_APP_AUTH0_DOMAIN);
export const auth0ClientId = getOrEmpty(
  import.meta.env.VITE_APP_AUTH0_CLIENT_ID
);
export const auth0Audience = getOrEmpty(
  import.meta.env.VITE_APP_AUTH0_AUDIENCE
);
export const auth0Scope = getOrEmpty(import.meta.env.VITE_APP_AUTH0_SCOPE);

export const nodeEnv = getOrEmpty(import.meta.env.NODE_ENV);

export const canUseGoogleIntegration = getBoolean(
  import.meta.env.VITE_APP_USE_GOOGLE_INTEGRATION ?? true
);
export const googleAppId = getOrEmpty(import.meta.env.VITE_APP_GOOGLE_APP_ID);
export const googleApiKey = getOrEmpty(import.meta.env.VITE_APP_GOOGLE_API_KEY);
export const datagustoLegacyHost = getOrEmpty(
  import.meta.env.VITE_APP_DATAGUSTO_LEGACY_HOST
);
export const isStandalone: boolean =
  import.meta.env.VITE_APP_IS_STANDALONE === "true"; // MEMO: CLI引数のため、booleanとして初期化できない(常にstring)
