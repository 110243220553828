<script setup lang="ts">
import { computed } from "vue";
import { useAuthStore } from "@/store/auth";
import { RequiredPermission } from "@/services/user-license/UserLicenseSpecification";
import CheckUserLicense from "@/components/common/CheckUserLicense/CheckUserLicense.vue";
import { storeToRefs } from "pinia";

const authStore = useAuthStore();

const { userProfile } = storeToRefs(authStore);

const props = defineProps<{
  requiredPermissions: RequiredPermission;
}>();

const userLicenseTypeWithFallback = computed(
  () => userProfile.value?.license_type ?? `simulation_user`
);
</script>

<template>
  <CheckUserLicense
    v-slot="slotProps"
    :required-permissions="props.requiredPermissions"
    :user-license-type="userLicenseTypeWithFallback"
  >
    <slot v-bind="slotProps"></slot>
  </CheckUserLicense>
</template>
