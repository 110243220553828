import { IError } from "@/error/types";
import { RouteLocationNormalizedLoaded } from "vue-router";
import { ErrorFactory } from "@/error/factory";
import { IErrorProcessor } from "../interface";

export class PageBasedErrorProcessor implements IErrorProcessor {
  process({
    error,
    opts,
  }: {
    error: IError;
    opts: { route: RouteLocationNormalizedLoaded };
  }): IError {
    const pageBasedErrorBase = ErrorFactory.createPageBasedBase({ error });
    /**
     * CAUTION:
     * route.nameの場合、バックエンドから返されるキーとは違って
     * 場所によってはswitch-caseで早い方でひっかけられてしまう場合もあるので
     * 順番には十分に注意してください。（a-z辞書順で後ろのものをcase文の上に持ってくるのを推奨）
     */
    switch (opts.route.name) {
      case "create-ai-from-recipe-id-input-download":
        pageBasedErrorBase.actionMessage = `ファイルの読み込みに失敗しました。ファイルの書式やレシピに記載の必要なデータが入力されているかを確認して、再度アップロードしてください。テンプレートファイルの使用をおすすめします。詳しくは画面右上のヘルプメニューからマニュアルを参照してください。`;
        break;

      case "create-ai-customize-recipe-id-input-download":
      case "create-ai-recreate-model-id-input-download":
        pageBasedErrorBase.actionMessage = `ファイルの読み込みに失敗しました。ファイルの書式やデータの内容に不備がないかを確認して、再度アップロードしてください。テンプレートファイルの使用をおすすめします。詳しくは画面右上のヘルプメニューからマニュアルを参照してください。`;
        break;

      case "create-ai-customize-recipe-id-select":
      case "create-ai-recreate-model-id-select":
      case "create-ai-from-data-id-select":
        // 一応、改行を反映してくれる(テンプレートの方でwhite-spaceをcss設定している）ので、箇条書きのところはそのままにしてください
        pageBasedErrorBase.actionMessage = `AIの作成に失敗しました。以下のガイドをもとに設定内容を確認して再度実行してください。それでも解決しない場合は右上のヘルプメニューよりdatagustoに問い合わせてください。`;
        pageBasedErrorBase.actions = [
          "変数は少なくとも２つ以上スイッチをオンにしてください",
          "カテゴリ型および日時型のデータは数値型に変更ができない場合があります",
        ];
        break;

      case "create-ai-from-data-id-input":
        pageBasedErrorBase.actionMessage = `ファイルの読み込みに失敗しました。ファイルの書式やデータの内容に不備がないかを確認して、再度アップロードしてください。詳しくは画面右上のヘルプメニューからマニュアルを参照してください。`;
        break;

      case "simulate-id-input":
        pageBasedErrorBase.actionMessage = `ファイルの読み込みに失敗しました。ファイルの書式やAIのレシピに記載の必要なデータが入力されているかを確認して、再度アップロードしてください。テンプレートファイルの使用をおすすめします。詳しくは画面右上のヘルプメニューからマニュアルを参照してください。`;
        break;

      case "simulate-id-setup":
        pageBasedErrorBase.actionMessage = `${error.actionMessage} \n\n 予測に失敗しました。画面右上のヘルプメニューよりdatagustoに問い合わせてください。`;
        break;
    }

    return pageBasedErrorBase;
  }
}
