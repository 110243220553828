export const CUSTOMER_RELATIONS = {
  NOTION: {
    MANUAL: {
      LINK: "https://datagusto.zendesk.com/hc/ja/categories/4409053421209-%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB",
      LABEL: "マニュアル",
    },
  },
  ZENDESK: {
    Q_AND_A: {
      LINK: "https://datagusto.zendesk.com/hc/ja/community/topics",
      LABEL: "Q&A",
    },
    COMMUNITY_TOP: {
      LINK: "https://datagusto.zendesk.com/hc/ja",
      LABEL: "コミュニティTOP",
    },
    CONTACT: {
      LINK: "https://datagusto.zendesk.com/hc/ja/requests/new",
      LABEL: "お問い合わせ",
    },
  },
};
